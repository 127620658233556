/**
 * Copyright (C) Digify - All Rights Reserved.
 *
 * THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
 * UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT,
 * VIA ANY MEDIUM IS STRICTLY PROHIBITED.
 *
 * The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
 * for any purpose other than the purpose for which they were provided to you.
 *
 * The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
 * the warranties of merchantability, fitness for a particular purpose and non infringement.
 * In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
 * whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
 * or the use or other dealings in the software.
 *
 * The above copyright notice and this permission notice shall be included in all copies
 * or substantial portions of the Software.
 */

import { CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getFeedbackData, postFeedback } from './config/apiCalls';
import { useOrderData } from './context/orderContext';
import Page1Desktop from './page1/desktop';
import Page1Mobile from './page1/mobile';
import Page2Mobile from './page2';
import Page3 from './page3';

function App() {
  const { setOrderData, orderData } = useOrderData();
  const [page, setPage] = useState(1);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { inputData } = useOrderData();
  const searchParams = new URLSearchParams(document.location.search);

  const fetchFeedBackData = useCallback(async () => {
    if (searchParams.get('token')) {
      const res2 = await getFeedbackData(searchParams.get('token'));
      if (res2.status === 200) {
        setOrderData(res2.data);
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchFeedBackData();
      setLoading(false);
    })();
  }, []);

  const submitFeedback = async () => {
    setSubmitLoading(true);
    const res = await postFeedback(searchParams.get('token'), inputData);
    if (res.status === 201) {
      setPage(2);
    } else if (res.response?.data?.length > 0) {
      if (res.response?.data[0]?.order_item?.length > 0) {
        if (
          res.response?.data[0]?.order_item[0] === 'This field must be unique.'
        ) {
          setPage(3);
        }
      }
    } else {
      toast.error('مشکلی پیش آمده. لطفا دوباره امتحان کنید', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    }
    setSubmitLoading(false);
  };

  if (loading)
    return (
      <Grid
        container
        height={'100vh'}
        justifyContent="center"
        alignItems="center">
        <CircularProgress size="70px" />
      </Grid>
    );

  switch (page) {
    case 1:
      if (matches)
        return (
          <Page1Mobile
            submitLoading={submitLoading}
            submitFeedback={submitFeedback}
            orderInfo={orderData}
          />
        );
      else
        return (
          <Page1Desktop
            submitLoading={submitLoading}
            submitFeedback={submitFeedback}
            orderInfo={orderData}
          />
        );

    case 2:
      return <Page2Mobile orderInfo={orderData} />;
    case 3:
      return <Page3 orderInfo={orderData} />;

    default:
      return;
  }
}

export default App;
