/**
 * Copyright (C) Digify - All Rights Reserved.
 *
 * THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
 * UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT,
 * VIA ANY MEDIUM IS STRICTLY PROHIBITED.
 *
 * The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
 * for any purpose other than the purpose for which they were provided to you.
 *
 * The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
 * the warranties of merchantability, fitness for a particular purpose and non infringement.
 * In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
 * whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
 * or the use or other dealings in the software.
 *
 * The above copyright notice and this permission notice shall be included in all copies
 * or substantial portions of the Software.
 */

import { useTheme } from '@emotion/react';
import { Button, CircularProgress, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import ProductCard from './components/productCard';
import Style from './style';

const Page1Desktop = ({ orderInfo, submitFeedback, submitLoading }) => {
  const theme = useTheme();
  const isBigDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Style isBigDesktop={isBigDesktop} container>
      <Grid container className="header">
        <img src={orderInfo?.store_logo?.image} />
        <h1>{orderInfo?.store_name}</h1>
      </Grid>
      <Grid container className="content">
        <Grid container>
          <Grid item xs={6}>
            <h2>
              {orderInfo?.first_name + ' ' + orderInfo?.last_name} عزیز ، ممنون
              از خرید شما
            </h2>
            <h3>
              با شرکت در نظرسنجی، به ما در ارائه خدمات بهتر، یاری می دهید.
            </h3>
          </Grid>
          <Grid
            alignItems="flex-start"
            item
            container
            justifyContent="flex-end"
            xs={6}>
            <Button
              onClick={submitFeedback}
              className="submit_btn"
              variant="contained"
              color="primary">
              {submitLoading ? (
                <CircularProgress style={{ color: '#FFF' }} />
              ) : (
                'ثبت نظر'
              )}
            </Button>
          </Grid>
        </Grid>
        <Grid container mt={6}>
          {orderInfo?.items?.map(e => (
            <ProductCard key={e.id} orderInfo={orderInfo} product={e} />
          ))}
        </Grid>
      </Grid>
    </Style>
  );
};

export default Page1Desktop;
