/**
 * Copyright (C) Digify - All Rights Reserved.
 *
 * THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
 * UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT,
 * VIA ANY MEDIUM IS STRICTLY PROHIBITED.
 *
 * The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
 * for any purpose other than the purpose for which they were provided to you.
 *
 * The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
 * the warranties of merchantability, fitness for a particular purpose and non infringement.
 * In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
 * whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
 * or the use or other dealings in the software.
 *
 * The above copyright notice and this permission notice shall be included in all copies
 * or substantial portions of the Software.
 */

import styled from '@emotion/styled';
import { Grid } from '@mui/material';

const Style = styled(Grid)`
  .header {
    height: 80px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    img {
      width: 52px;
      height: 52px;
      border-radius: 8px;
    }
    h1 {
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      margin-left: 8px;
    }
  }
  .content {
    padding: ${props => (props.isBigDesktop ? '48px 12.5vw' : '48px 120px')};
    h2 {
      font-size: 22px;
      font-weight: 500;
      margin: 0;
    }
    h3 {
      color: #6a6f80;
      font-size: 18px;
      font-weight: 400;
    }
    .submit_btn {
      height: 48px;
      width: 240px;
    }
  }
`;

export default Style;
