/**
 * Copyright (C) Digify - All Rights Reserved.
 *
 * THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
 * UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT,
 * VIA ANY MEDIUM IS STRICTLY PROHIBITED.
 *
 * The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
 * for any purpose other than the purpose for which they were provided to you.
 *
 * The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
 * the warranties of merchantability, fitness for a particular purpose and non infringement.
 * In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
 * whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
 * or the use or other dealings in the software.
 *
 * The above copyright notice and this permission notice shall be included in all copies
 * or substantial portions of the Software.
 */

import styled from '@emotion/styled';
import { Button, Grid, IconButton } from '@mui/material';
import React from 'react';
import { ReactComponent as Close } from '../../../assets/svg/clsoe.svg';

const Style = styled(Grid)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #101820aa;
  z-index: 20;
  .content {
    width: calc(100vw - 32px);
    border-radius: 10px;
    background-color: #fff;
    padding: 16px;
    padding-bottom: 24px;
    position: relative;
  }
  .close {
    top: 16px;
    right: 16px;
    position: absolute;
    padding: 0;
  }
  h3 {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }
  .button {
    width: 100px;
    height: 42px;
  }
`;

const DeleteModal = ({ onClose, confirm }) => {
  return (
    <Style container justifyContent="center" alignItems="center">
      <Grid container className="content">
        <IconButton onClick={onClose} className="close">
          <Close />
        </IconButton>
        <Grid mt={1} container>
          <h3>آیا از حذف تصویر اطمینان دارید؟</h3>
        </Grid>
        <Grid mt={7} container justifyContent="flex-end">
          <Button
            onClick={onClose}
            className="button"
            variant="text"
            color="secondary">
            انصراف
          </Button>
          <Button
            onClick={confirm}
            className="button"
            variant="outlined"
            color="primary">
            حذف
          </Button>
        </Grid>
      </Grid>
    </Style>
  );
};

export default DeleteModal;
