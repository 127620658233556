/**
 * Copyright (C) Digify - All Rights Reserved.
 *
 * THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
 * UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT,
 * VIA ANY MEDIUM IS STRICTLY PROHIBITED.
 *
 * The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
 * for any purpose other than the purpose for which they were provided to you.
 *
 * The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
 * the warranties of merchantability, fitness for a particular purpose and non infringement.
 * In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
 * whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
 * or the use or other dealings in the software.
 *
 * The above copyright notice and this permission notice shall be included in all copies
 * or substantial portions of the Software.
 */

import styled from '@emotion/styled';
import { Grid } from '@mui/material';

const Style = styled(Grid)`
  padding: 16px;
  max-width: 750px;
  margin: auto;
  h1 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #6a6f80;
  }
  img {
    width: 48px;
    height: 48px;
    border-radius: 8px;
  }
  .box {
    box-shadow: 0px 4px 8px rgba(72, 52, 147, 0.08);
    border-radius: 8px;
    padding: 24px 16px;
    background-color: #fff;
  }
  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
  p {
    margin: 0;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
  .icon_btn {
    padding: 0;
  }
`;

export default Style;
