/**
 * Copyright (C) Digify - All Rights Reserved.
 *
 * THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
 * UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT,
 * VIA ANY MEDIUM IS STRICTLY PROHIBITED.
 *
 * The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
 * for any purpose other than the purpose for which they were provided to you.
 *
 * The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
 * the warranties of merchantability, fitness for a particular purpose and non infringement.
 * In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
 * whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
 * or the use or other dealings in the software.
 *
 * The above copyright notice and this permission notice shall be included in all copies
 * or substantial portions of the Software.
 */

import { Button, Grid, IconButton, useMediaQuery } from '@mui/material';
import React from 'react';
import Style from './style';
import { ReactComponent as Success } from '../assets/svg/success.svg';
import { ReactComponent as Twitter } from '../assets/svg/twitter.svg';
import { ReactComponent as Linkedin } from '../assets/svg/linkedin.svg';
import { ReactComponent as Instagram } from '../assets/svg/instagram.svg';
import { useTheme } from '@emotion/react';

const Page2 = ({ orderInfo }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Style container>
      {matches && (
        <Grid container justifyContent="space-between">
          <h1>{orderInfo?.store_name}</h1>
          <img src={orderInfo?.store_logo?.image} />
        </Grid>
      )}
      <Grid mt={8} container></Grid>
      <Grid mt={8} container className="box">
        <Grid container justifyContent="center">
          <Success />
        </Grid>
        <Grid justifyContent="center" container mt={3}>
          <h2>{orderInfo?.first_name + ' ' + orderInfo?.last_name} عزیز</h2>
        </Grid>
        <Grid mt={5} container justifyContent="center">
          <p style={{ textAlign: 'center' }}>
            نظر شما پس از تایید کارشناسان ما، در وبسایت منتشر خواهد شد
          </p>
        </Grid>
        <Grid mt={1} container justifyContent="center">
          <p style={{ textAlign: 'center' }}>
            ممنون از زمانی که به ما اختصاص دادید.
          </p>
        </Grid>
        <Grid justifyContent="center" mt={4} container>
          <Button
            onClick={() => {
              window.location.assign(
                `https://${window.location.host}/profile/orders/${orderInfo?.id}/`
              );
            }}
            variant="contained"
            color="primary">
            مشاهده جزییات سفارش
          </Button>
        </Grid>
      </Grid>
      <Grid justifyContent="center" mt={2} container className="box">
        <Grid justifyContent="center" container>
          <h4>مارو در شبکه های اجتماعی، دنبال کنید.</h4>
        </Grid>
        <Grid
          width={matches ? '70%' : '40%'}
          mt={4}
          justifyContent="space-around"
          container>
          {orderInfo?.store_social_media?.twitter && (
            <IconButton
              onClick={() => {
                window.location.assign(orderInfo?.store_social_media?.twitter);
              }}
              className="icon_btn">
              <Twitter />
            </IconButton>
          )}
          {orderInfo?.store_social_media?.linkedin && (
            <IconButton
              onClick={() => {
                window.location.assign(orderInfo?.store_social_media?.linkedin);
              }}
              className="icon_btn">
              <Linkedin />
            </IconButton>
          )}
          {orderInfo?.store_social_media?.instagram && (
            <IconButton
              onClick={() => {
                window.location.assign(
                  orderInfo?.store_social_media?.instagram
                );
              }}
              className="icon_btn">
              <Instagram />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Style>
  );
};

export default Page2;
