/**
 * Copyright (C) Digify - All Rights Reserved.
 *
 * THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
 * UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT,
 * VIA ANY MEDIUM IS STRICTLY PROHIBITED.
 *
 * The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
 * for any purpose other than the purpose for which they were provided to you.
 *
 * The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
 * the warranties of merchantability, fitness for a particular purpose and non infringement.
 * In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
 * whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
 * or the use or other dealings in the software.
 *
 * The above copyright notice and this permission notice shall be included in all copies
 * or substantial portions of the Software.
 */

import { Button, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import ProductCard from './components/productCard';
import Style from './style';

const Page1 = ({ orderInfo, submitFeedback, submitLoading }) => {
  return (
    <Style container>
      <Grid
        alignItems="center"
        className="header"
        justifyContent="space-between"
        container>
        <h1>{orderInfo?.store_name}</h1>
        <img src={orderInfo?.store_logo?.image} />
      </Grid>
      <Grid className="thanks" container mt={5}>
        <Grid justifyContent="center" container>
          <h2>
            {orderInfo?.first_name + ' ' + orderInfo?.last_name} عزیز، ممنون از
            خرید شما
          </h2>
        </Grid>
        <Grid justifyContent="center" container>
          <p>با شرکت در نظرسنجی، ما را در ارائه خدمات بهتر یاری کنید</p>
        </Grid>
      </Grid>
      <Grid container className="products_list">
        {orderInfo?.items?.map(e => (
          <ProductCard product={e} key={e.id} />
        ))}
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="footer">
        <Button
          disabled={submitLoading}
          onClick={submitFeedback}
          variant="contained"
          color="primary"
          fullWidth>
          {submitLoading ? (
            <CircularProgress style={{ color: '#FFF' }} />
          ) : (
            'ثبت نظر'
          )}
        </Button>
      </Grid>
    </Style>
  );
};

export default Page1;
