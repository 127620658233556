/**
 * Copyright (C) Digify - All Rights Reserved.
 *
 * THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
 * UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT,
 * VIA ANY MEDIUM IS STRICTLY PROHIBITED.
 *
 * The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
 * for any purpose other than the purpose for which they were provided to you.
 *
 * The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
 * the warranties of merchantability, fitness for a particular purpose and non infringement.
 * In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
 * whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
 * or the use or other dealings in the software.
 *
 * The above copyright notice and this permission notice shall be included in all copies
 * or substantial portions of the Software.
 */

import styled from '@emotion/styled';
import { Grid } from '@mui/material';

const Style = styled(Grid)`
  padding: 0;
  .header {
    padding: 16px;
    background-color: #fff;
    h1 {
      margin: 0;
      font-size: 18px;
      color: #6a6f80;
    }
    img {
      width: 52px;
      height: 52px;
      border-radius: 8px;
    }
  }
  .thanks {
    h2 {
      font-size: 18px;
      margin: 0;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      color: #6a6f80;
      margin: 0;
      margin-top: 4px;
    }
  }
  .products_list {
    padding: 0 16px;
    margin-top: 40px;
  }
  .footer {
    height: 78px;
    box-shadow: 0px 0px 20px rgba(72, 52, 147, 0.12);
    margin-top: 45px;
    padding: 0 15px;
  }
`;

export default Style;
