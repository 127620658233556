/**
 * Copyright (C) Digify - All Rights Reserved.
 *
 * THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
 * UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT,
 * VIA ANY MEDIUM IS STRICTLY PROHIBITED.
 *
 * The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
 * for any purpose other than the purpose for which they were provided to you.
 *
 * The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
 * the warranties of merchantability, fitness for a particular purpose and non infringement.
 * In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
 * whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
 * or the use or other dealings in the software.
 *
 * The above copyright notice and this permission notice shall be included in all copies
 * or substantial portions of the Software.
 */

import styled from '@emotion/styled';
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Rating,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import ReactImageUploading from 'react-images-uploading';
import { ReactComponent as EmptyStar } from '../../../assets/svg/emptyStar.svg';
import { ReactComponent as Star } from '../../../assets/svg/star.svg';
import { ReactComponent as Add } from '../../../assets/svg/add.svg';
import { ReactComponent as Trash } from '../../../assets/svg/trash.svg';
import DeleteModal from './deleteModal';
import { useOrderData } from '../../../context/orderContext';
import { uploadImage } from '../../../config/apiCalls';

const Style = styled(Grid)`
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(72, 52, 147, 0.08);
  background-color: #fff;
  padding: 16px;
  img {
    height: 48px;
    width: 48px;
    border-radius: 4px;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    margin-left: 16px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  .MuiRating-root {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .MuiRating-label {
    display: none;
  }
  .upload_hint {
    font-size: 12px;
    font-weight: 400;
    color: #adaaba;
    margin-left: 4px;
  }
  .upload_btn {
    height: 84px;
    width: 84px;
    min-width: 84px;
    border-radius: 8px;
    border-style: dashed;
  }
  .upload_btn:hover {
    border-style: dashed;
    border: 1px dashed rgba(72, 52, 147, 0.5);
  }
  .uploaded_image {
    height: 84px;
    width: 84px;
    min-width: 84px;
    border-radius: 8px;
    margin-left: 8px;
    object-fit: cover;
  }
  .uploadedImgCont {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .uploadedImgCont::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .trashCont {
    border: 0.5px solid #dad6e9;
    border-radius: 4px 0px 4px 0px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    background-color: #fff;
  }
`;

const ProductCard = ({ product }) => {
  const { orderData, inputData, setInputData } = useOrderData();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [images, setImages] = useState([]);
  const [description, seetDescription] = useState('');
  const [deleteImageModal, setDeleteImageModal] = useState(false);
  const [rate, setRate] = useState(0);
  const searchParams = new URLSearchParams(document.location.search);

  const onChange = async img => {
    setLoading(true);
    setError(false);
    if (img[0]?.file?.size > 3000000) {
      setError(true);
    } else {
      const formData = new FormData();
      formData.append('image', img[0].file);
      const res = await uploadImage(searchParams.get('token'), formData);
      if (res.status === 201) {
        const index = orderData.items.findIndex(s => s.id === product.id);
        let cloneData = [...inputData];
        cloneData[index]['images'] = [
          ...cloneData[index]['images'],
          res.data.uuid
        ];
        // cloneData[index]['images'] = cloneData[index]['images'].filter(
        //   i => !isEmpty(i)
        // );
        setInputData(cloneData);
        setImages(prev => [...prev, img[0]]);
      }
    }

    setLoading(false);
  };

  const handleDescriptionChanged = e => {
    const text = e.target.value;
    seetDescription(text);

    const index = orderData.items.findIndex(s => s.id === product.id);
    let cloneData = [...inputData];
    if (!cloneData[index]) return;
    cloneData[index]['description'] = text;
    setInputData(cloneData);
  };

  const handleChangeRate = rate => {
    setRate(+rate.target.value);
    const index = orderData.items.findIndex(s => s.id === product.id);
    let cloneData = [...inputData];
    if (!cloneData[index]) return;
    cloneData[index]['score'] = +rate.target.value;
    setInputData(cloneData);
  };

  const removeImage = () => {
    const newImages = [...images];
    newImages.splice(deleteImageModal - 1, 1);
    setImages(newImages);
    const index = orderData.items.findIndex(s => s.id === product.id);
    let cloneData = [...inputData];
    cloneData[index]['images'].splice(deleteImageModal - 1, 1);
    setInputData(cloneData);
    setDeleteImageModal(false);
  };

  return (
    <Style container>
      {deleteImageModal && (
        <DeleteModal
          confirm={removeImage}
          onClose={() => setDeleteImageModal(false)}
        />
      )}
      <Grid container alignItems="center">
        <img
          src={
            product?.variant?.product_serialized?.images_serialized[0]?.image
          }
        />
        <h4>{product?.variant?.product_serialized?.name}</h4>
      </Grid>
      <Grid mt={2} container>
        <p>رضایت شما از کیفیت محصول چقدر است؟</p>
      </Grid>
      <Grid mt={3} container>
        <Rating
          value={rate}
          onChange={handleChangeRate}
          icon={<Star />}
          emptyIcon={<EmptyStar />}
        />
      </Grid>
      <Grid mt={8} container>
        <p>نقاط مثبت و منفی محصول چه بود؟</p>
      </Grid>
      <Grid container mt={2}>
        <TextField
          fullWidth
          value={description}
          onChange={handleDescriptionChanged}
          multiline
          rows={4}
          variant="outlined"
          placeholder="توضیحات موردنظر خود را وارد کنید"
        />
      </Grid>
      <Grid alignItems="center" mt={5} container>
        <p>بارگذاری تصاویر محصول</p>
        <span className="upload_hint">(حداکثر حجم هر فایل 3 مگابایت)</span>
      </Grid>
      <Grid mt={2} container>
        <ReactImageUploading
          value={images}
          onChange={onChange}
          dataURLKey="data_url">
          {({ imageList, onImageUpload }) => (
            <>
              <Grid
                className="uploadedImgCont"
                flexWrap="nowrap"
                overflow="scroll"
                container>
                <Button
                  disabled={loading}
                  onClick={onImageUpload}
                  className="upload_btn"
                  variant="outlined">
                  {loading ? <CircularProgress /> : <Add />}
                </Button>
                {imageList?.map((e, i) => (
                  <Grid position="relative" key={i}>
                    <img className="uploaded_image" src={e?.data_url} />
                    <Grid
                      className="trashCont"
                      position="absolute"
                      onClick={() => setDeleteImageModal(i + 1)}
                      bottom={0}
                      right={0}>
                      <Trash />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </ReactImageUploading>
      </Grid>
      {error && (
        <Grid mt={2} container>
          <Alert
            style={{ width: '100%', borderRadius: '8px' }}
            severity="error">
            {' '}
            حجم تصویر، بیشتر از 3 مگابایت است!
          </Alert>
        </Grid>
      )}
    </Style>
  );
};

export default ProductCard;

// const index = survey.items.findIndex(s => s.id === productId)
// let cloneData = [...data]
// cloneData[index]['images'][imageIndex] = res.data.uuid
// cloneData[index]['images'] = cloneData[index]['images'].filter(i => !isEmpty(i))
// setData(cloneData)
