/**
 * Copyright (C) Digify - All Rights Reserved.
 *
 * THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
 * UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT,
 * VIA ANY MEDIUM IS STRICTLY PROHIBITED.
 *
 * The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
 * for any purpose other than the purpose for which they were provided to you.
 *
 * The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
 * the warranties of merchantability, fitness for a particular purpose and non infringement.
 * In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
 * whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
 * or the use or other dealings in the software.
 *
 * The above copyright notice and this permission notice shall be included in all copies
 * or substantial portions of the Software.
 */

import styled from '@emotion/styled';
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Rating,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import ReactImageUploading from 'react-images-uploading';
import { ReactComponent as EmptyStar } from '../../../assets/svg/emptyStar.svg';
import { ReactComponent as Star } from '../../../assets/svg/star.svg';
import { ReactComponent as Trash } from '../../../assets/svg/trash.svg';
import { ReactComponent as Add } from '../../../assets/svg/add.svg';
import DeleteModal from './deleteModal';
import { uploadImage } from '../../../config/apiCalls';
import { useOrderData } from '../../../context/orderContext';

const Style = styled(Grid)`
  padding: 24px;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  .header_pro {
    img {
      width: 184px;
      height: 184px;
      border-radius: 8px;
    }
    h5 {
      margin: 0;
      font-size: 22px;
      font-weight: 500;
    }
    .price {
      p {
        margin: 0;
        color: #adaaba;
      }
      span {
        margin-left: 12px;
        color: #6a6f80;
      }
    }
  }
  .desc_cont {
    h6 {
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }
    span {
      color: #adaaba;
      font-size: 14px;
      font-weight: 400;
      margin-left: 8px;
    }
  }
  .upload_btn {
    height: 124px;
    width: 124px;
    min-width: 124px;
    border-radius: 8px;
    border-style: dashed;
  }
  .upload_btn:hover {
    border-style: dashed;
    border: 1px dashed rgba(72, 52, 147, 0.5);
  }
  .uploaded_image {
    height: 124px;
    width: 124px;
    min-width: 124px;
    border-radius: 8px;
    margin-left: 8px;
    object-fit: cover;
  }
  .uploadedImgCont {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .uploadedImgCont::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .trashCont {
    border: 0.5px solid #dad6e9;
    border-radius: 8px 0px 8px 0px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    background-color: #fff;
    cursor: pointer;
  }
`;

const ProductCard = ({ product, orderInfo }) => {
  const { orderData, inputData, setInputData } = useOrderData();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [images, setImages] = useState([]);
  const [description, seetDescription] = useState('');
  const [deleteImageModal, setDeleteImageModal] = useState(false);
  const [rate, setRate] = useState(0);
  const searchParams = new URLSearchParams(document.location.search);

  const onChange = async img => {
    setLoading(true);
    setError(false);
    if (img[0]?.file?.size > 3000000) {
      setError(true);
    } else {
      const formData = new FormData();
      formData.append('image', img[0].file);
      const res = await uploadImage(searchParams.get('token'), formData);
      if (res.status === 201) {
        const index = orderData.items.findIndex(s => s.id === product.id);
        let cloneData = [...inputData];
        cloneData[index]['images'] = [
          ...cloneData[index]['images'],
          res.data.uuid
        ];
        // cloneData[index]['images'] = cloneData[index]['images'].filter(
        //   i => !isEmpty(i)
        // );
        setInputData(cloneData);
        setImages(prev => [...prev, img[0]]);
      }
    }

    setLoading(false);
  };

  const handleDescriptionChanged = e => {
    const text = e.target.value;
    seetDescription(text);

    const index = orderData.items.findIndex(s => s.id === product.id);
    let cloneData = [...inputData];
    if (!cloneData[index]) return;
    cloneData[index]['description'] = text;
    setInputData(cloneData);
  };

  const handleChangeRate = rate => {
    setRate(+rate.target.value);
    const index = orderData.items.findIndex(s => s.id === product.id);
    let cloneData = [...inputData];
    if (!cloneData[index]) return;
    cloneData[index]['score'] = +rate.target.value;
    setInputData(cloneData);
  };

  const removeImage = () => {
    const newImages = [...images];
    newImages.splice(deleteImageModal - 1, 1);
    setImages(newImages);
    const index = orderData.items.findIndex(s => s.id === product.id);
    let cloneData = [...inputData];
    cloneData[index]['images'].splice(deleteImageModal - 1, 1);
    setInputData(cloneData);
    setDeleteImageModal(false);
  };

  return (
    <Style container>
      {deleteImageModal && (
        <DeleteModal
          confirm={removeImage}
          onClose={() => setDeleteImageModal(false)}
        />
      )}
      <Grid className="header_pro" container>
        <img
          src={
            product?.variant?.product_serialized?.images_serialized[0]?.image
          }
        />
        <Grid pl={3} flexGrow={1}>
          <Grid container height="100%" alignItems="space-between">
            <Grid container justifyContent="space-between">
              <h5>{product?.variant?.product_serialized?.name}</h5>
              <Rating
                value={rate}
                onChange={handleChangeRate}
                sx={{
                  fontSize: '5rem',
                  '& .MuiRating-icon': {
                    width: '3rem'
                  }
                }}
                icon={<Star />}
                emptyIcon={<EmptyStar />}
              />
            </Grid>
            <Grid className="price" alignItems="flex-end" container>
              <Grid container>
                <p>کد سفارش: </p>
                <span style={{ marginRight: '12px' }}>
                  {orderInfo?.reference_code}
                </span>
              </Grid>
              <Grid container>
                <p>قیمت اصلی: </p>
                <span>
                  {typeof product?.variant?.online_cost === 'number' &&
                    product?.variant?.online_cost?.toLocaleString()}{' '}
                  تومان
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid mt={8} className="desc_cont" container>
        <Grid container>
          <h6>نکات مثبت و منفی محصول، چه بود؟</h6>
        </Grid>
        <Grid mt={2} container>
          <TextField
            value={description}
            onChange={handleDescriptionChanged}
            variant="outlined"
            placeholder="توضیحات موردنظر خود را، وارد کنید."
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
      <Grid mt={8} className="desc_cont" container>
        <Grid alignItems="center" container>
          <h6>بارگذاری تصاویر محصول</h6>
          <span>(حداکثر حجم هر فایل 3 مگابایت)</span>
        </Grid>
        <Grid mt={3} container>
          <ReactImageUploading
            value={images}
            onChange={onChange}
            dataURLKey="data_url">
            {({ imageList, onImageUpload }) => (
              <>
                <Grid
                  className="uploadedImgCont"
                  flexWrap="nowrap"
                  overflow="scroll"
                  container>
                  <Button
                    disabled={loading}
                    onClick={onImageUpload}
                    className="upload_btn"
                    variant="outlined">
                    {loading ? <CircularProgress /> : <Add />}
                  </Button>
                  {imageList?.map(e => (
                    <Grid position="relative" key={e.data_url}>
                      <img className="uploaded_image" src={e?.data_url} />
                      <Grid
                        className="trashCont"
                        position="absolute"
                        bottom={0}
                        right={0}>
                        <Trash />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </ReactImageUploading>
        </Grid>
        {error && (
          <Grid mt={2} container>
            <Alert
              style={{ width: '100%', borderRadius: '8px' }}
              severity="error">
              {' '}
              حجم تصویر، بیشتر از 3 مگابایت است!
            </Alert>
          </Grid>
        )}
      </Grid>
    </Style>
  );
};

export default ProductCard;
